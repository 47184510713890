<template>
  <v-dialog
    fullscreen
    :value="visible"
    @click:outside="$emit('close')"
    height="100%"
  >
    <v-card v-if="gDriveLink" style="d-flex align-center">
      <iframe
        height="100%"
        width="100%"
        class="gdrive-frame"
        :src="gDriveLink"
        title="Google Drive video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

    <div style="width: 12.5%; height: 16.66%; position: absolute; opacity: 0; right: 0px; top: 0px;">&nbsp;</div>
        
      <v-fab-transition>
        <v-btn
          class="close-button"
          color="white"
          style="opacity: 100%"
          dark
          top
          right
          fixed
          fab
          @click="$emit('close')"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FullScreenGDriveVideoDialog",
  props: {
    link: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      gDriveLink: null,
      resourceKey: null,
    };
  },
  created() {
    var VID_REGEX = /[-\w]{25,}(?!.*[-\w]{25,})/;
    var videoId = this.link.match(VID_REGEX);
    // var videoURL = new URL(this.link);
    // this.resourceKey = videoURL.searchParams.get("resourcekey");
    // this.gDriveLink = `https://drive.google.com/file/d/${videoId}/preview?resourcekey=${this.resourceKey}`;
    this.gDriveLink = `https://drive.google.com/file/d/${videoId}/preview`;

  },
};
</script>
<style scoped>
.gdrive-frame {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>