<template>
  <div class="all-video-lecture-videos-page">
    <v-row justify="center">
      <v-col md="6">
        <v-list-item v-if="lectureObj" class="mt-3">
          <v-list-item-avatar class="outlined">
            <v-img :src="lectureObj.faculty.image"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="subtitle-2">
            {{ lectureObj.faculty.full_name }}
          </v-list-item-content>
          <p class="my-0 caption grey--text pr-2">
            {{ moment(lectureObj.uploaded).format("hh:mmA, Do MMM") }}
          </p>
        </v-list-item>
        <div v-if="lectureObj && lectureObj.video_link_platform != 'custom_or_error'" class="mx-4">
          <p class="text-caption ma-0">
            {{lectureObj.module.title}}
          </p>
          <p class="content-description mb-2"
            v-html="TextHelper.linkify(lectureObj.description)"
          >
            {{  }}
          </p>
          <v-img
            width="100%"
            class="cursor-pointer"
            :src="getVideoThumbnail(lectureObj.link, lectureObj.video_link_platform)"
            :aspect-ratio="16 / 9"
            @click="showVideoPlayback(lectureObj)"
          >
            <div class="d-flex fill-height justify-center align-center">
              <v-btn
                class="image-icon align-self-center"
                icon
                width="70px"
                height="70px"
                @click="showVideoPlayback(lectureObj)"
                ><v-icon size="70px" color="white">mdi-play</v-icon></v-btn
              >
            </div>
          </v-img>
        </div>
        <div class="mx-2"  v-else>
          <custom-video-player     
            v-if="showCustomVideo"
            :visible="true"
            :link="getCustomLink"
            :key="getCustomLink"
          /> 
        </div>
      </v-col>
    </v-row>

    <FullScreenYTDialog
      v-if="videoLink && lectureObj.video_link_platform == 'yt_video'"
      :visible="showVideo"
      :link="videoLink"
      @close="
        showVideo = false;
        videoLink = null;
      "
    />

    <FullScreenGDriveVideoDialog
      v-if="videoLink && lectureObj.video_link_platform == 'gdrive_video'"
      :visible="showVideo"
      :link="videoLink"
      @close="
        showVideo = false;
        videoLink = null;
      "
    />
    
    <intract-create-edit-sheet
      persistent
      title="Edit Video Lecture"
      :visible="videoLectureEditSheet.visible"
      @close="
        videoLectureEditSheet.visible = false;
        videoLectureEditSheet.editId = null;
      "
      :fields="formFields"
      submit-button-text="Edit video lecture"
      :endpoint="endpoints.videolecturesViewSet"
      :edit-id="videoLectureEditSheet.editId"
      :edit-success-message="videoLectureEditSheet.editSuccessMessage"
      :data-object="videoLectureEditSheet.obj"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (videoLectureEditSheet.obj = obj)"
      :afterEditObjectReceived="afterVideoObjectReceived"
      :enable-media="!(videoLectureEditSheet.obj.link || videoLectureEditSheet.obj.video_file)"
    />

    <!-- DELETE CONFIRMATION DIALOG -->
    <confirmation-dialog
      title="Are you sure you want to delete this Video lecture?"
      description="This action cannot be undone"
      :visible="showDeleteDialog"
      delete-dialog
      @successCallback="deleteLectureItem"
      @failureCallback="showDeleteDialog = false"
    />
  </div>
</template>

<script>
import TextHelper from '@utils/text_helper';
import moment from "moment";
import EventBus from "@utils/event_bus";
import Mixins from "@utils/mixins";
import FullScreenYTDialog from "@components/dialogs/FullScreenYTDialog";
import FullScreenGDriveVideoDialog from "@components/dialogs/FullScreenGDriveVideoDialog";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import { mapActions } from "vuex";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import CustomVideoPlayer from "@components/base/CustomVideoPlayer";

export default {
  name: "VideoLecture",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],

  components: {
    FullScreenYTDialog,
    IntractCreateEditSheet,
    ConfirmationDialog,
    FullScreenGDriveVideoDialog,
    CustomVideoPlayer,
  },

  data() {
    return {
      TextHelper,
      moment: moment,
      showCustomVideo: true,
      appBarTitle: null,
      videoLink: null,
      lectureObj: null,
      showDeleteDialog: false,
      videoLectureEditSheet: {
        visible: false,
        editSuccessMessage: "Post edited successfully!",
        editId: null,
        obj: {
          module: this.$route.params.moduleId,
          faculty: null,
        },
      },
    };
  },

  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    getVideoThumbnail(link, platform) {
      
      var VID_REGEX = null;

      if (platform == "yt_video"){
        VID_REGEX =
          /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        var videoId = link.match(VID_REGEX)[1];
        return `https://img.youtube.com/vi/${videoId}/0.jpg`;
      }
      else {
        return "https://www.pngkit.com/png/full/267-2678423_bacteria-video-thumbnail-default.png";
      }
    },

    async afterVideoObjectReceived(obj) {
      if (!obj.video_file){
        obj.link = { link: obj.link };
      }
      return obj;
    },

    async showVideoPlayback(video) {
      this.showVideo = true;
      this.videoLink = video.link;
    },

    async getVideo() {
      var url =
        this.endpoints.videolecturesViewSet +
        this.$route.params.lectureId +
        "/";
      this.lectureObj = await this.api.call(this.essentials, url);
      this.appBarTitle = this.lectureObj.title;
    },

    async setAppBar() {
      this.setAppBarOptions({
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.videoLectureEditSheet.editId = this.lectureObj.id;
              this.videoLectureEditSheet.visible = true;
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },

    async deleteLectureItem() {
      var url = this.endpoints.videolecturesViewSet + this.lectureObj.id + "/";
      this.showDeleteDialog = false;
      console.log(url);
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        console.log("test");
        this.showSnackbar({
          title: "Video lecture has been successfully deleted!",
          type: "success",
        });
        EventBus.$emit(
          "video_lectures__lecture_deleted",
          Number(this.$route.params.lectureId)
        );

        this.$router.back();
      }
    },

    onRefresh() {
      this.showCustomVideo = false;
      this.getVideo();
      this.showCustomVideo = true;
    },
  },

  computed: {
    getCustomLink(){
      return this.lectureObj.video_file ? this.lectureObj.video_file.document : this.lectureObj.link
    }, 

    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          required: false,
          max: 3000,
        },
        video_file: {
          fieldType: CreateEditFieldTypes.VIDEOUPLOAD,
          multiple: false,
          label: "Upload a Video",
          helper: "Upload a video from device", 
          valid: () => {
            return (this.videoLectureEditSheet.obj.video_file != null || this.videoLectureEditSheet.obj.link != null)
          }
        },
        link: {
          fieldType: CreateEditFieldTypes.LINKUPLOAD,
          multiple: false,
          label: "Video",
          required: this.videoLectureEditSheet.obj.video_file ? false : true,
          helper: "Link a YouTube, Google Drive Video or add a Custom Video Link",
          valid: () => {
            return (this.videoLectureEditSheet.obj.video_file != null || this.videoLectureEditSheet.obj.link != null)
          }
        }
      };
    },
    canManage() {
      return (
        this.lectureObj &&
        (this.currentUser.is_admin ||
          this.currentUser.id == this.lectureObj.faculty.id)
      );
    },
  },

  async created() {
    this.videoLectureEditSheet.obj.creator = this.currentUser.id;
    this.videoLectureEditSheet.obj.faculty = this.currentUser.id;
    this.appBarTitle = this.$route.params.lectureName ?? "Lecture Video";
    await this.getVideo();
    if (this.canManage) this.setAppBar();
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },
};
</script>

<style scoped>
.image-icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>